<template>
  <div
    @click="$emit('toggle')"
    class="thread-item"
    :class="{ 'thread-item--opened': opened }"
  >
    <div class="dropdown-wrapper">
      <dropdownIcon class="dropdown" />
    </div>
    <div class="content-wrapper">
      <div class="header">
        <div class="left">
          <div class="property property--subject" v-html="item.subject">
          </div>
          <div class="property property--from">From: {{ item.email_from }}</div>
          <div class="property property--to">to {{ item.email_to }}</div>
        </div>
        <div class="right">
          <div class="property property--date">
            {{ item.date }}
          </div>
          <div class="actions">
            <template v-if="item.actions">
              <template v-for="btn in item.actions">
                <div
                  v-if="buttons[btn]"
                  :key="buttons[btn].id"
                  class="actions__item-wrapper"
                  :title="buttons[btn].title"
                  :name="`thread__${item.id}__action-item__${buttons[btn].id}`"
                  @click.stop.prevent="handleAction(buttons[btn].type)"
                  >
                  <!-- @click="buttons[btn].action" -->
                  <component
                    :is="buttons[btn].component"
                    v-bind="buttons[btn].props"
                    v-on="buttons[btn].events"
                    class="actions__item"
                    :class="[
                      'actions__item--' + buttons[btn].type,
                      buttons[btn].class,
                      {
                        disabled: item.disabled,
                      },
                    ]"
                    :is-ack="item.is_ack"
                  />
                  <component
                    v-if="buttons[btn].modal"
                    :is="buttons[btn].modal"
                    :type="buttons[btn].type"
                    :to="`thread__${item.id}__action-item__${buttons[btn].id}`"
                  />
                </div>
              </template>
            </template>
          </div>
          <div class="flex justify-end">
              <button v-if="item.attachments && item.attachments.length > 0" @click.stop.prevent="showActionModal(item, 'email-trail-attachments')" class="p-2 relative rounded-lg hover:bg-gray-100 flex items-center gap-2 font-semibold cursor-pointer">
                  <font-awesome-icon class="text-lg text-blue-600" icon="link" />
                  <div class="rounded-full bg-blue-600 text-xs p-0.5 absolute -top-1 left-5 text-white" style="min-width: 20px; min-height: 20px;">
                    {{checkAttachmentsLength(item.attachments.length)}}
                  </div>
                  Attachments
              </button>
          </div>
        </div>
      </div>
      <div v-if="opened" class="description break-all">
        <div
          class="property property--description text-xs leading-5 2xl:text-sm 2xl:leading-6"
          v-html="item.html_body"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { buttons } from "@/components/dashboard-components/checklist/utils";

import { EventBus } from "@/main.js";

const dropdownIcon = () => import("@/assets/icons/dashboard/dropdown-thin.svg");

export default {
  name: "thread-item",
  components: {
    dropdownIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttons,
    };
  },
  mounted() {
  },
  methods: {
    handleAction(e) {
      EventBus.$emit('activityAction', {type: e, data: this.item});
    },
    async showActionModal(email, type) {
      let payload = {data:email, type}
      this.$emit('showEmailAttachments', payload)
    },
    checkAttachmentsLength(length){
      return length > 10 ? '10+' : length
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.thread-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  .dropdown-wrapper {
    padding: 0 11px;
    cursor: pointer;
    .dropdown {
      transition: transform 0.3s;
      transform: rotate(0deg);
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-grow: 1;
    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        .actions {
          display: inline-flex;
          align-items: center;
          gap: 12px;
          &__item {
            cursor: pointer;
            transition: filter 0.3s;
            &--check {
              &.disabled {
                fill: #afafaf;
                cursor: default;
              }
            }
            &.hover {
              &:hover {
                filter: brightness(1.2);
              }
            }
          }
        }
      }
    }
  }
  .property {
    &--subject {
      // font-size: 1rem;
      @apply text-sm 2xl:text-base;
      font-weight: 700;
      color: #0d69d5;
      // display: flex;
      align-items: center;
      gap: 10px;
    }
    &--from,
    &--to {
      // font-size: toRem(12px);
      font-weight: 700;
      @apply text-xs;
    }
    &--from {
      color: #a7a7a7;
    }
    &--to {
      color: #000000;
    }
    &--description {
      margin-top: 9px;
      color: rgba(40, 40, 40, 0.7);
      font-weight: 500;
    }
    &--date {
      color: rgba(0, 0, 0, 0.6);
      // font-size: toRem(14px);
      font-weight: 600;
      font-size: toRem(10px);
      text-align: right;
      @apply 2xl:text-sm;
    }
  }
  &--opened {
    .dropdown-wrapper {
      .dropdown {
        // transition: transform .3s;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
